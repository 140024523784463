<template>
    <div>
        <v-btn
            v-for="item in availableItems"
            :key="item.text"
            text
            :to="item.route">
            <v-icon left dark>
                {{ item.icon }}
            </v-icon>
            {{ item.text }}
        </v-btn>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            items: [
                {
                    text: "Home",
                    icon: "mdi-home",
                    route: "/"
                },
                {
                    icon: "mdi-calendar",
                    text: "Event Approval",
                    route: "/event-approval"
                },
                {
                    icon: "mdi-database",
                    text: "Admin",
                    route: "/admin"
                },
            ]
        }
    },
    computed: {
        ...mapGetters({ hasPolicy: "authentication/hasPolicy" }),
        availableItems() {
            return this.items.filter(i => i.policy == null || this.hasPolicy(i.policy));
        }
    }
}
</script>
